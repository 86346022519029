import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgTitleDatenschutz from "../images/page_title_datenschutz.png"

const Datenschutz = props => {
  return (
    <Layout>
      <Seo title="datenschutz" />      
      <section className="datenschutz container">
        <img
          className="page-title"
          src={imgTitleDatenschutz}
          alt="kaffeerösterei in peine"
        />

        <p>
          die nutzung unserer seite ist ohne eine angabe von personenbezogenen
          daten möglich. für die nutzung einzelner services unserer seite können
          sich hierfür abweichende regelungen ergeben, die in diesem falle
          nachstehend gesondert erläutert werden. ihre personenbezogenen daten
          (z.b. name, anschrift, e-mail, telefonnummer, u.ä.) werden von uns nur
          gemäß den bestimmungen des deutschen datenschutzrechts verarbeitet.
          daten sind dann personenbezogen, wenn sie eindeutig einer bestimmten
          natürlichen person zugeordnet werden können. die rechtlichen
          grundlagen des datenschutzes finden sie im bundesdatenschutzgesetz
          (bdsg) und dem telemediengesetz (tmg). nachstehende regelungen
          informieren sie insoweit über die art, den umfang und zweck der
          erhebung, die nutzung und die verarbeitung von personenbezogenen daten
          durch den anbieter
        </p>
        <p>
          das kleine röstwerk
          <br />
          thorsten tessmer
          <br />
          heinrich-hertz-straße 7<br />
          31228 peine
        </p>
        <p>
          wir weisen darauf hin, dass die internetbasierte datenübertragung
          sicherheitslücken aufweist, ein lückenloser schutz vor zugriffen durch
          dritte somit unmöglich ist.
        </p>

        <p>
          nutzer erhalten mit dieser datenschutzerklärung information über die
          art, den umfang und zweck der erhebung und verwendung ihrer daten
          durch den verantwortlichen anbieter erhoben und verwendet werden.
        </p>

        <p>
          den rechtlichen rahmen für den datenschutz bilden das
          bundesdatenschutzgesetz (bdsg) und das telemediengesetz (tmg).
  
        </p>
        <p>
          <strong>erfassung allgemeiner informationen</strong>
        </p>

        <p>
          mit jedem zugriff auf dieses angebot werden durch uns bzw. den
          webspace-provider automatisch informationen erfasst. diese
          informationen, auch als server-logfiles bezeichnet, sind allgemeiner
          natur und erlauben keine rückschlüsse auf ihre person.
        </p>

        <p>
          erfasst werden unter anderem: name der webseite, datei, datum,
          datenmenge, webbrowser und webbrowser-version, betriebssystem, der
          domainname ihres internet-providers, die sogenannte referrer-url (jene
          seite, von der aus sie auf unser angebot zugegriffen haben) und die
          ip-adresse.
        </p>

        <p>
          ohne diese daten wäre es technisch teils nicht möglich, die inhalte
          der webseite auszuliefern und darzustellen. insofern ist die erfassung
          der daten zwingend notwendig. darüber hinaus verwenden wir die
          anonymen informationen für statistische zwecke. sie helfen uns bei der
          optimierung des angebots und der technik. wir behalten uns zudem das
          recht vor, die log-files bei verdacht auf eine rechtswidrige nutzung
          unseres angebotes nachträglich zu kontrollieren.
        </p>

        <p>
          <strong>cookies</strong>
          <br />
          <br />
          wir verwenden auf unserer seite sog. cookies zum wiedererkennen
          mehrfacher nutzung unseres angebots, durch denselben
          nutzer/internetanschlussinhaber. cookies sind kleine textdateien, die
          ihr internet-browser auf ihrem rechner ablegt und speichert. sie
          dienen dazu, unseren internetauftritt und unsere angebote zu
          optimieren. es handelt sich dabei zumeist um sog. „session-cookies“,
          die nach dem ende ihres besuches wieder gelöscht werden.
        </p>

        <p>
          teilweise geben diese cookies jedoch informationen ab, um sie
          automatisch wieder zu erkennen. diese wiedererkennung erfolgt aufgrund
          der in den cookies gespeicherten ip-adresse. die so erlangten
          informationen dienen dazu, unsere angebote zu optimieren und ihnen
          einen leichteren zugang auf unsere seite zu ermöglichen.
          <br />
          sie können die installation der cookies durch eine entsprechende
          einstellung ihres browsers verhindern; wir weisen sie jedoch darauf
          hin, dass sie in diesem fall gegebenenfalls nicht sämtliche funktionen
          unserer website vollumfänglich nutzen können.
        </p>

        <p>
          <strong>serverdaten</strong>
        </p>

        <p>
          aus technischen gründen werden u.a. folgende daten, die ihr
          internet-browser an uns bzw. an unseren webspace-provider übermittelt,
          erfasst (sogenannte serverlogfiles):
        </p>

        <p>
          - browsertyp und -version
          <br />
          - verwendetes betriebssystem
          <br />
          - webseite, von der aus sie uns besuchen (referrer url)
          <br />
          - webseite, die sie besuchen
          <br />
          - datum und uhrzeit ihres zugriffs
          <br />
          - ihre internet protokoll (ip)-adresse.
          <br />
          <br />
          diese anonymen daten werden getrennt von ihren eventuell angegebenen
          personenbezogenen daten gespeichert und lassen so keine rückschlüsse
          auf eine bestimmte person zu. sie werden zu statistischen zwecken
          ausgewertet, um unseren internetauftritt und unsere angebote
          optimieren zu können.
        </p>

        <p>
          <strong>registrierungsfunktion</strong>
        </p>

        <p>
          wir bieten ihnen auf unserer seite die möglichkeit, sich dort zu
          registrieren. die im zuge dieser registrierung eingegebenen daten, die
          aus der eingabemaske des registrierungsformular ersichtlich sind
          werden ausschließlich für die verwendung unseres angebots erhoben und
          gespeichert. mit ihrer registrierung auf unserer seite werden wir
          zudem ihre ip-adresse und das datum sowie die uhrzeit ihrer
          registrierung speichern. dies dient in dem fall, dass ein dritter ihre
          daten missbraucht und sich mit diesen daten ohne ihr wissen auf
          unserer seite registriert, als absicherung unsererseits.&nbsp; eine
          weitergabe an dritte erfolgt nicht. ein abgleich der so erhobenen
          daten mit daten, die möglicherweise durch andere komponenten unserer
          seite erhoben werden, erfolgt ebenfalls nicht.
        </p>

        <p>
          <strong>kontaktmöglichkeit</strong>
        </p>

        <p>
          wir bieten ihnen auf unserer seite die möglichkeit, mit uns per e-mail
          und/oder über ein kontaktformular in verbindung zu treten. in diesem
          fall werden die vom nutzer gemachten angaben zum zwecke der
          bearbeitung seiner kontaktaufnahme gespeichert. eine weitergabe an
          dritte erfolgt nicht. ein abgleich der so erhobenen daten mit daten,
          die möglicherweise durch andere komponenten unserer seite erhoben
          werden, erfolgt ebenfalls nicht.
        </p>

        <p>
          <strong>
            einsatz von google-analytics mit anonymisierungsfunktion
          </strong>
        </p>

        <p>
          wir setzen auf unserer seite google-analytics, einen webanalysedienst
          der firma google inc., 1600 amphitheatre parkway, mountain view, ca
          94043 usa, nachfolgend „google“ ein. google-analytics verwendet sog.
          „cookies“, textdateien, die auf ihrem computer gespeichert werden und
          hierdurch eine analyse der benutzung der website durch sie
          ermöglichen.
        </p>

        <p>
          die durch diese cookies erzeugten informationen, beispielsweise zeit,
          ort und häufigkeit ihres webseiten-besuchs einschließlich ihrer
          ip-adresse, werden an google in den usa übertragen und dort
          gespeichert.
        </p>

        <p>
          wir verwenden auf unserer website google-analytics mit einer
          ip-anonymisierungsfunktion. ihre ip-adresse wird in diesem fall von
          google schon innerhalb von mitgliedstaaten der europäischen union oder
          in anderen vertragsstaaten des abkommens über den europäischen
          wirtschaftsraum gekürzt und dadurch anonymisiert.
        </p>

        <p>
          google wird diese informationen benutzen, um ihre nutzung unserer
          seite auszuwerten, um reports über die websiteaktivitäten für uns
          zusammenzustellen und um weitere mit der websitenutzung und der
          internetnutzung verbundene dienstleistungen zu erbringen. auch wird
          google diese informationen gegebenenfalls an dritte übertragen, sofern
          dies gesetzlich vorgeschrieben oder soweit dritte diese daten im
          auftrag von google verarbeiten.
        </p>

        <p>
          google wird, nach eigenen angaben, in keinem fall ihre ip-adresse mit
          anderen daten von google in verbindung bringen. sie können die
          installation der cookies durch eine entsprechende einstellung ihrer
          browser-software verhindern; wir weisen sie jedoch darauf hin, dass
          sie in diesem fall gegebenenfalls nicht sämtliche funktionen unserer
          website vollumfänglich nutzen können.
        </p>

        <p>
          des weiteren bietet google für die gängigsten browser eine
          deaktivierungsoption an, welche ihnen mehr kontrolle darüber gibt,
          welche daten von google erfasst und verarbeitet werden. sollte sie
          diese option aktivieren, werden keine informationen zum website-besuch
          an google analytics übermittelt. die aktivierung verhindert aber
          nicht, dass informationen an uns oder an andere von uns gegebenenfalls
          eingesetzte webanalyse-services übermittelt werden. weitere
          informationen zu der von google bereitgestellten deaktivierungsoption
          sowie zu der aktivierung dieser option, erhalten sie über
          nachfolgenden link:
        </p>

        <p>
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </p>

        <p>
          <strong>
            einsatz von youtube-komponenten mit erweitertem datenschutzmodus
          </strong>
        </p>

        <p>
          auf unserer webseite setzen wir komponenten (videos) des unternehmens
          youtube, llc 901 cherry ave., 94066 san bruno, ca, usa, einem
          unternehmen der google inc., amphitheatre parkway, mountain view, ca
          94043, usa, ein.
        </p>

        <p>
          hierbei nutzen wir die von youtube zur verfügung gestellte option „
          erweiterter datenschutzmodus”.
        </p>

        <p>
          wenn sie eine seite aufrufen, die über ein eingebettetes video
          verfügt, wird eine verbindung zu den youtube-servern hergestellt und
          dabei der inhalt durch mitteilung an ihren browser auf der
          internetseite dargestellt.
        </p>

        <p>
          laut den angaben von youtube werden im „ - erweiterten
          datenschutzmodus -“ nur daten an den youtube-server übermittelt,
          insbesondere welche unserer internetseiten sie besucht haben, wenn sie
          das video anschauen. sind sie gleichzeitig bei youtube eingeloggt,
          werden diese informationen ihrem mitgliedskonto bei youtube
          zugeordnet. dies können sie verhindern, indem sie sich vor dem besuch
          unserer website von ihrem mitgliedskonto abmelden.
        </p>

        <p>
          weitere informationen zum datenschutz von youtube werden von google
          unter dem folgenden link bereitgestellt:
        </p>

        <p>
          <a href="https://www.google.de/intl/de/policies/privacy/">
            https://www.google.de/intl/de/policies/privacy/
          </a>
        </p>

        <p>
          <strong>einsatz von paypal als zahlungsart</strong>
        </p>

        <p>
          sollten sie sich im rahmen ihres bestellvorgangs für eine bezahlung
          mit dem online-zahlungsdienstleister paypal entscheiden, werden im
          rahmen der so ausgelösten bestellung ihre kontaktdaten an paypal
          übermittelt. paypal ist ein angebot der paypal (europe) s.à.r.l. &amp;
          cie. s.c.a., 22-24 boulevard royal, l-2449 luxembourg. paypal
          übernimmt dabei die funktion eines online-zahlungsdienstleisters sowie
          eines treuhänders und bietet käuferschutzdienste an.
        </p>

        <p>
          bei den an paypal übermittelten personenbezogenen daten handelt es
          sich zumeist um vorname, nachname, adresse, telefonnummer, ip-adresse,
          e-mail-adresse, oder andere daten, die zur bestellabwicklung
          erforderlich sind, als auch daten, die im zusammenhang mit der
          bestellung stehen, wie anzahl der artikel, artikelnummer,
          rechnungsbetrag und steuern in prozent, rechnungsinformationen, usw.
        </p>

        <p>
          diese übermittelung ist zur abwicklung ihrer bestellung mit der von
          ihnen ausgewählten zahlungsart notwendig, insbesondere zur bestätigung
          ihrer identität, zur administration ihrer zahlung und der
          kundenbeziehung.
        </p>

        <p>
          bitte beachten sie jedoch: personenbezogenen daten können seitens
          paypal auch an leistungserbringer, an subunternehmer oder andere
          verbundene unternehmen weitergegeben werden, soweit dies zur erfüllung
          der vertraglichen verpflichtungen aus ihrer bestellung erforderlich
          ist oder die personenbezogenen daten im auftrag verarbeitet werden
          sollen.
        </p>

        <p>
          abhängig von der über paypal ausgewählten zahlungsart, z.b. rechnung
          oder lastschrift, werden die an paypal übermittelten personenbezogenen
          daten von paypal an wirtschaftsauskunfteien übermittelt. diese
          übermittlung dient der identitäts- und bonitätsprüfung in bezug auf
          die von ihnen getätigte bestellung. um welche auskunfteien es sich
          hierbei handelt und welche daten von paypal allgemein erhoben,
          verarbeitet, gespeichert und weitergegeben werden, entnehmen sie der
          datenschutzerklärung von paypal unter
        </p>

        <p>
          <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">
            https://www.paypal.com/de/webapps/mpp/ua/privacy-full
          </a>
        </p>

        <p>
          <strong>auskunft/widerruf/löschung</strong>
        </p>

        <p>
          sie können sich aufgrund des bundesdatenschutzgesetzes bei fragen zur
          erhebung, verarbeitung oder nutzung ihrer personenbezogenen daten und
          deren berichtigung, sperrung, löschung oder einem widerruf einer
          erteilten einwilligung unentgeltlich an uns wenden. wir weisen darauf
          hin, dass ihnen ein recht auf berichtigung falscher daten oder
          löschung personenbezogener daten zusteht, sollte diesem anspruch keine
          gesetzliche aufbewahrungspflicht entgegenstehen.
        </p>

        <p>
          <a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">
            muster-datenschutzerklärung
          </a> der
          <a href="https://www.ratgeberrecht.eu/">
            anwaltskanzlei weiß &amp; partner
          </a>
        </p>
      </section>
    </Layout>
  )
}

export default Datenschutz
